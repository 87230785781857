<template>
  <b-card>
    <b-card-header>
      <div>
        <b-card-title :style="color"> {{ title }}</b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body v-if="productsData">
      <b-row>
        <b-col md="4">
          <h6 class="align-self-center my-auto text-capitalize text-secondary">
            {{ $t("Product_Index.Product_Name") }}
          </h6>
        </b-col>
        <b-col md="2">
          <div class="d-flex align-items-center">
            <span
              class="font-weight-bold text-body-heading mr-1 text-secondary"
            >
              {{ $t("Product_Index.SKU") }}</span
            >
          </div>
        </b-col>
        <b-col md="2">
          <div class="d-flex align-items-center">
            <span
              class="font-weight-bold text-body-heading mr-1 text-secondary"
            >
              {{ $t("Product_Index.Visitors") }}</span
            >
          </div>
        </b-col>
        <b-col md="2">
          <div class="d-flex align-items-center">
            <span
              class="font-weight-bold text-body-heading mr-1 text-secondary"
            >
              {{ $t("Product_Index.VisitorsCost") }}</span
            >
          </div>
        </b-col>
        <b-col md="2">
          <div class="d-flex align-items-center">
            <span
              class="font-weight-bold text-body-heading mr-1 text-secondary"
            >
              {{ $t("Product_Index.Cart_Variants_Number") }}</span
            >
          </div>
        </b-col>
      </b-row>

      <b-row v-for="(product, index) in productsData" :key="index">
        <b-col md="4" class="mt-2">
          <b-link
            style="color: black"
            :to="{
              path:
                (product.is_new ? '/New' : '/Used') +
                '/ShowProduct/variant/show/' +
                product.id,
            }"
          >
            <h6 class="align-self-center my-auto text-capitalize">
              {{ product.product_name }}
            </h6>
          </b-link>
        </b-col>
        <b-col md="2" class="mt-2">
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">{{
              product.sku
            }}</span>
          </div>
        </b-col>
        <b-col md="2" class="mt-2">
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">{{
              product.visitors
            }}</span>
          </div>
        </b-col>
        <b-col md="2" class="mt-2">
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">{{
                 parseFloat( product.visitors_cost).toFixed(2)
            }}</span>
          </div>
        </b-col>
        <b-col md="2" class="mt-2">
          <span class="font-weight-bold text-body-heading mr-1">{{
            product.cart_variants_number
          }}</span>
        </b-col>
      </b-row>


      <b-row v-if="paginationOption">
        <b-col md="12" class="mt-3">

            <b-pagination
            align="center"
            @input="fetchData"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>

    </b-card-body>
    <b-card-body style="text-align: center" v-else>
      <h6 class="align-self-center my-auto text-secondary">
        {{ $t("Product_Index.No_Products") }}
      </h6>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  props: ["title", "chartData", "color" , "paginationOption"],
  components: {
    VueApexCharts,
  },
  async created() {
    await this.fetchData();
  },
  data() {
    return {
        productsData: null,
        perPage: 10,
        currentPage: 1,
        totalRows:1,
    };
  },

  methods: {
    async fetchData() {
      await axios
        .get("variants/top-products/"+this.perPage+"/get"+ "?page=" +this.currentPage)
        .then((result) => {
          this.productsData = result.data.data.data;
          this.totalRows = result.data.data.total;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
  },
};
</script>
